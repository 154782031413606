import { head } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import styles from './stylist-suggestions-index.module.scss'
import BodyTypePopup from 'components/shared/bodyTypePopup';
import { Modal, ModalHeader, ModalBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { getStylishPick, getSizeInfo, getCustomerTags, getRangeBetweenTwoNumbers, getStylishPickTest, isShipmentLimitReched, saveCustomerViewProductActivityLocal, addCustomerViewProductActivity, capitializeString, isCartLimitReached } from 'components/shared/globalFunctions';
import Gconfig from 'globalconfig';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false },);
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { getCustomerDetailInfo } from 'components/shared/customerDataFunctions';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { triggerCartPopper } from "redux/actions/cartActions";
import { addProductToCart, addMultipleProductToCart, addProductToCartFromFunctionComp, addSaveLaterProduct, updateSaveLaterProduct } from "components/shared/cartFunctions"
import BoardsListTooltip from 'components/shared/boardsListTooltip';
import { addRemoveFavoriteItem } from 'components/shared/productFavFunctions';
import SignupPopup from 'components/shared/signupPopup';
import Link from 'next/link';
import { Pagination, Navigation, FreeMode } from 'swiper';
import moment from 'moment';
import { updateProfile } from 'components/shared/customerDataFunctions';
import { coinFlipLogic } from '@/functions/customer-functions';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import Loader from 'components/shared/loadingComp';
import NodeCache from 'node-cache';
import UpdatePrefrencePopup from 'components/shared/updatePrefrencesPopup';
import Login from 'components/shared/login';
import jsCookie from 'js-cookie';
import { fetchCartSaveLaterSuccess } from 'redux/actions/cartActions'
import { useWindowEvent } from '@/hooks/use-window-event';
import { useResponsive } from '@/libraries/responsive';
interface IProps {
  fromNew: boolean,
  isLogIn: boolean,
}
// let cache: NodeCache;
const cache = new NodeCache({ stdTTL: 600, checkperiod: 2, useClones: true });

var alreadyViewProductArr:any = []
var tempViewProdArrObj:any = []

const StylistSuggestions: React.FC<IProps> = ({ fromNew, isLogIn = true }) => {


  const [stylistSuggestionsPopup, setstylistSuggestionsPopup] = useState(false);
  const [prefrenceSuggestionsPopup, setprefrenceSuggestionsPopup] = useState(false)
  const [selectedStylishPickObj, setSelectedStylishPickObj] = useState({})
  const [allStylistPickData, setAllStylishData] = useState([])
  const [customerInfo, setCustomerInfo] = useState('')
  const [msg, setMsg] = useState({ type: "", msg: "" })
  const [openSignupModal, setOpenSignupModal] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [inCompleteProfileOverlay, setIncompleteProfileOverlay] = useState(false)
  const [bodyTypePopup, setBodyTypePopup] = useState(false)
  const [isPairNotFound, setIsPairNotFound] = useState(false)
  const [noOfIteminPerSlide, setNoOfIteminPerSlide] = useState(3)
  const [checkduplicatePairIdArr, setCheckduplicatePairIdArr] = useState([])
  const [currentSwiperSlide, setCurrentSwiperSlide] = useState(0)
  const [alreadyFetchingNewData, setAlreadyFetchingNewData] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [alreadyFetchedAllPair, setAlreadyFetchedAllPair] = useState(false)
  const [isStylistPickShow, setIsStylistPickShow] = useState(true)
  const [compSetProfile, setCompSetProfile] = useState(false)
  const [completeProfileNotFill, setCompleteProfileNotFill] = useState(false)
  const [showSaveForLaterBtn, setShowSaveForLaterBtn] = useState(false)
  const [showSizeLaterPopupIndex, setShowSizeLaterPopupIndex] = useState(-1)
  const [stylistFilterArr, setStylistFilterArr] = useState([])
  const [selectedStylistFilter, setSelectedStylistFilter] = useState('')
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

  const customer = useAppSelector(state => state.customer.customer);
  const customerBoards = useAppSelector(state => state.customerBoards);
  const customerPrefrence = useAppSelector((state) => state.customerPreferences);
  const cart = useAppSelector((state) => state.cart);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const { isTablet } = useResponsive();
  const dispatch = useAppDispatch();

  useWindowEvent(
    'click',
    (e) => {
      try {
        if (e?.target?.className && e?.target?.className.indexOf('save_later_size_btn') == -1) {
          setShowSizeLaterPopupIndex(-1)
        }
      }
      catch (e) {
        console.log(e)
      }
    },
    true
  )

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1190 && window.innerWidth > 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    let isHideStylishPick: any = false

    if (customer != 'not_login') {
      if (isShipmentLimitReched(customer) == false && (customer?.tag_list.indexOf('CurrentlyBorrowing') == -1 || (customer?.tag_list.indexOf('CurrentlyBorrowing') > -1 && (customer?.tag_list?.join()?.indexOf('items') > -1  || customer?.tag_list.indexOf('2upgradeItems') > -1 || customer?.tag_list.indexOf('4upgradeItems') > -1 )))) {
        setShowSaveForLaterBtn(false)
      } else {
        setShowSaveForLaterBtn(true)
      }
    }

    if (isHideStylishPick == false) {
      if (jsCookie.get('customer_login_success') != undefined) {
        cache.del('stylistpick')
        jsCookie.remove('customer_login_success')
      }
      getStylishPickBySize(0);
      if (screen.width <= 767) {
        const element = document.getElementsByTagName("body")[0];
        element.addEventListener("touchmove", (e: any) => {
          try {
            if (e.target.className && e.target.className.indexOf('newBoard-tooltip') == -1 && e.target.className.indexOf('fa-heart') == -1 && e.target.className.indexOf('boardItem') == -1) {
              let boardsList = document.querySelectorAll('.newBoard-tooltip')
              boardsList.forEach((val, ind) => {
                if (boardsList[ind]) {
                  boardsList[ind].style.display = "none"
                }
              })
            }
          } catch (error) {
            console.log(error);
          }
        });
        return () => element.removeEventListener('touchmove', () => { })
      }
    }


  }, [customer != 'not_login'])

  const getStylishPickBySize = async (currentPageNo: any, isRefresh: any, selectedStylistFilterFromParam: any) => {
    let dressSize: any = '', bodyType = '', braSize = '', height_ft = '', height_in = '', top_size = '', jeans_size = '', weight = '', bs = ['', ''], ds = ['', ''], fname = "", lname = "", birthday = "", phone = "";
    let obj = {}
    let isInCompletePrefrence = false

    // ye condition htani hy jb asad pair found api me correct bhejyga
    if (fromNew == false) {
      setIsPairNotFound(false)
      setIncompleteProfileOverlay(false)
    }

    if (customer != "not_login") {

      customer.tag_list.map((tag: any) => {

        if (tag.indexOf('Dress Size:') > -1) {
          dressSize = tag.split(':')
          if (dressSize && dressSize.length > 0) {
            ds[1] = dressSize[1] ? dressSize[1] : ''
            ds[0] = dressSize[2] ? dressSize[2] : ''
          }
        }

        if (tag.indexOf('Jeans Size:') > -1) {
          jeans_size = tag.split(':')[1]
        }

        if (tag.indexOf('Shirt & Top:') > -1) {
          top_size = tag.split(':')[1]
        }

      })

      obj = {
        dressSize: ds[0],
        dressNumber: ds[1],
        topSize: top_size,
        jeansSize: jeans_size,
      }

      if (top_size == '' || ds[0] == '' || ds[1] == '' || jeans_size == '') {
        isInCompletePrefrence = true
        setCompleteProfileNotFill(true)
        if (customer != 'not_login') {
          setIncompleteProfileOverlay(true)
        }
      }

    }

    let api_obj: any = {
      "top_size": obj.topSize,
      "dress_size": `${obj.dressSize}-${obj.dressNumber}`,
      "jeans_size": obj.jeansSize,
      "page_num": currentPageNo,
      "all": true
    }

    if (customer == 'not_login' || (customer != 'not_login' && customer.has_subscription == false && isInCompletePrefrence == true)) {
      api_obj = {
        "noPreferences": true,
        "page_num": currentPageNo,
        "all": true
      }
    } else {
      let prefrenceString = `top_size=${obj.topSize}&dress_size=${obj.dressSize}-${obj.dressNumber}&jeans_size=${obj.jeansSize}&page_num=${currentPageNo}&all=true`;
      if (isInCompletePrefrence) {
        api_obj = {}
        api_obj = {
          "noPreferences": true
        }
        prefrenceString = 'noPreferences=true'
      }
    }

    if (fromNew == false) {
      api_obj.FilterCheck = true
    }

    let res = null;

    // from home page 
    if (fromNew == true) {
      if (customer == 'not_login' || (customer != 'not_login' && isInCompletePrefrence == true)) {
        res = cache.get('stylistpick');
      } else {
        let key = 'stylistpick_' + obj.topSize + '' + obj.dressSize + '' + obj.dressNumber + '' + obj.jeansSize
        res = cache.get(key);
      }
      if (!res) {
        res = await getStylishPick(api_obj, fromNew);
        if (currentPageNo == 0) {
          if (customer == 'not_login' || (customer != 'not_login' && isInCompletePrefrence == true)) {
            cache.set('stylistpick', res);
          } else {
            let key = 'stylistpick_' + obj.topSize + '' + obj.dressSize + '' + obj.dressNumber + '' + obj.jeansSize
            cache.set(key, res);
          }
        }
      }
    }
    // from stylist comp (test)
    else {
      api_obj.FilterString = selectedStylistFilterFromParam != undefined ? selectedStylistFilterFromParam : selectedStylistFilter
      res = await getStylishPickTest(api_obj, fromNew);
    }


    setAlreadyFetchingNewData(false)
    if (res.success && res.pairs) {

      if (fromNew == false && res.filteredTagList) {
        if (stylistFilterArr.length == 0) {
          setStylistFilterArr(res.filteredTagList)
        }
      }
      else {
        setStylistFilterArr([])
      }

      if (res.pair_found == false) {

        if (fromNew == false && res.pairs && res.pairs.length > 3) {

        } else {
          setIsPairNotFound(true)
          setIncompleteProfileOverlay(true)
        }


      }
      else {
        if (top_size != '' || ds[0] != '' || ds[1] != '' || jeans_size != '') {
          setIncompleteProfileOverlay(false)
        }
      }

      let stylishPickArr: any = (isRefresh == true) ? [] : allStylistPickData
      if (res.pairs.length == 0) return
      stylishPickArr = stylishPickArr.concat(res.pairs)


      stylishPickArr.length > 0 && stylishPickArr.map((pair_obj: any, item_ind: any) => {

        pair_obj.leftItem = {}
        pair_obj.topRightItem = {}
        pair_obj.bottomRightItem = {}

        if (pair_obj.product_pairs && pair_obj.product_pairs.length > 0) {

          let leftProdID = ""
          let rightProdID = ""
          let bottomProdID = ""
          let toatalAvailableItemForCount = 0
          pair_obj.product_pairs.map((prod_obj: any, prod_index: any) => {
            if (prod_obj.productSku.length == 1 && prod_obj.productSku[0]?.option1 == 'NONE') prod_obj.selectedSize = "NONE";
            if (prod_obj.left == true) {
              leftProdID += '' + prod_obj?.product_id
              pair_obj.leftItem = prod_obj
              toatalAvailableItemForCount += 1
            }
            else if (prod_obj.right == true) {
              rightProdID += '' + prod_obj?.product_id
              pair_obj.topRightItem = prod_obj
              toatalAvailableItemForCount += 1
            }
            else if (prod_obj.bottom == true) {
              bottomProdID += '' + prod_obj?.product_id
              pair_obj.bottomRightItem = prod_obj
              toatalAvailableItemForCount += 1
            }
            else { }
            pair_obj.mergeProdID = leftProdID + rightProdID + bottomProdID
            pair_obj.toatalAvailableItemForCount =  toatalAvailableItemForCount
          })
        }
      })

      const uniqueStylistArray = stylishPickArr.filter((obj:any, index:any, self:any) =>
        index === self.findIndex((o:any) => o.mergeProdID === obj.mergeProdID)
      );
      
      setAllStylishData(uniqueStylistArray)

      if(localStorage.getItem('customerViewedProductsArr')) {
        let customerViewedProductArr = JSON.parse(''+localStorage.getItem('customerViewedProductsArr'))
        if(customerViewedProductArr && customerViewedProductArr.length > 500) {
          addCustomerViewProductActivity(customerViewedProductArr)
          localStorage.removeItem('customerViewedProductsArr')
          let currentTimestamp : any = moment()?.valueOf();
          currentTimestamp = parseInt(currentTimestamp / 60000)
          jsCookie.set('lastProductViewActivityUpdateTime', currentTimestamp)
        }
      }

    }
    else if (res.success == false && res.message == "No product remaining") {
      setAlreadyFetchedAllPair(true)
      // console.log('all pair fetched')
    }
  }

  const _addProductToCart = (product: any, numberOfProd: string, from: any) => {
    setMsg({ type: "", msg: "" })
    dispatch(getCustomerTags(customer.customer_id)).then(() => {
      let prodImagesSingle:any = []
      if(product?.images?.length > 0){
        product?.images?.map((val)=>{
          prodImagesSingle.push(val?.product_image_url)
          })
      }
      let productObj = {
          created_by: product?.created_by,
          updated_by: product?.updated_by,
          created_at: product?.created_at,
          updated_at: product?.updated_at,
          colors: product?.colors,
          discount: product?.productSku?.length > 0 ? product?.productSku?.[0]?.discount : 0,
          images: prodImagesSingle,
          product_availability: product?.product_availability,
          product_catagory: product?.product_catagory,
          product_description: product?.product_description,
          product_detail: product?.product_detail,
          product_handle: product?.product_handle,
          product_id: product?.product_id,
          product_notes: product?.product_notes,
          product_shopify_id: product?.product_shopify_id,
          product_thumbnail: product?.product_thumbnail,
          product_title: product?.product_title,
          product_type_id: product?.product_type_id,
          product_vendor_id: product?.product_vendor_id,
          published_at: product?.published_at,
          retail: product?.productSku?.length > 0 ? product?.productSku?.[0]?.retail : 0,
          sale_price:  product?.productSku?.length > 0 ? product?.productSku?.[0]?.sale_price : 0,
          sizes: product?.productSku,
          size_and_fit: product?.size_and_fit,
          tags: product?.tags,
          vendor: product?.vendor_name,
          vendor_handle: product?.vendor_handle,
          status: product?.status,
          status_for_sale: product?.status_for_sale,
          status_for_sale_nonsubscriber: product?.status_for_sale_nonsubscriber,
          productExternalColorAttribute: product?.external_color?.attribute
      }

      let customerTag = customer.tag_list.join().toLowerCase();
      let nItems = false;

      if (customerTag.indexOf('items') > -1 && customerTag.indexOf('earlycheckin') < 0) {
        nItems = true;
      }else if(customerTag.indexOf('currentlyborrowing') > -1 && customerTag.indexOf('earlycheckin') < 0 && (customerTag.indexOf('items') > -1)){
        nItems = true;
      }
      // if (customerTag.indexOf('added items') > -1) {
      //   nItems = false;
      // }
      if (customerTag.indexOf('currentlyborrowing') > -1 && !nItems) {
        setMsg({ type: from, msg: "Oops! You have to send back your current items before checking out new ones." })
        return
      }

      if (numberOfProd == "multiple") {
        let product_obj = product
        let productArr = []

        let lItem = product_obj.leftItem;
        if (lItem && lItem.product_id != undefined) {
          if (lItem.selectedSize != "" && lItem.selectedSize != undefined && lItem.selectedSize != true) {
            productArr.push(lItem)
          }
          else {
            setMsg({ type: from, msg: "Please select a size" })
            return
          }
        }

        let rtItem = product_obj.topRightItem;
        if (rtItem && rtItem.product_id != undefined) {
          if (rtItem.selectedSize != "" && rtItem.selectedSize != undefined && rtItem.selectedSize != true) {
            productArr.push(rtItem)
          }
          else {
            setMsg({ type: from, msg: "Please select a size" })
            return
          }
        }

        let rbItem = product_obj.bottomRightItem;
        if (rbItem && rbItem.product_id != undefined) {
          if (rbItem.selectedSize != "" && rbItem.selectedSize != undefined && rbItem.selectedSize != true) {
            productArr.push(rbItem)
          }
          else {
            setMsg({ type: from, msg: "Please select a size" })
            return
          }
        }

        let customer_id = customer.customer_id;

        try {

          if (productArr.length == 0) {
            setMsg({ type: from, msg: "" })
            return
          }

          let cartArr: any = []
          productArr.length > 0 && productArr.map((_prod, ind) => {
            let _cartItemType = 1;
            let extra = "";
            let isShoes = false
            let size = _prod.selectedSize
            let sizeData = _prod.productSku.filter((obj: any) => { return (size == obj.option1) })
            let prodImages:any = []
            if(_prod?.images?.length > 0){
              _prod?.images?.map((val)=>{
                    prodImages.push(val?.product_image_url)
                })
            }
            let productObjForMultipleItems = {
              created_by: _prod?.created_by,
              updated_by: _prod?.updated_by,
              created_at: _prod?.created_at,
              updated_at: _prod?.updated_at,
              colors: _prod?.colors,
              discount: _prod?.productSku?.length > 0 ? _prod?.productSku?.[0]?.discount : 0,
              images: prodImages,
              product_availability: _prod?.product_availability,
              product_catagory: _prod?.product_catagory,
              product_description: _prod?.product_description,
              product_detail: _prod?.product_detail,
              product_handle: _prod?.product_handle,
              product_id: _prod?.product_id,
              product_notes: _prod?.product_notes,
              product_shopify_id: _prod?.product_shopify_id,
              product_thumbnail: _prod?.product_thumbnail,
              product_title: _prod?.product_title,
              product_type_id: _prod?.product_type_id,
              product_vendor_id: _prod?.product_vendor_id,
              published_at: _prod?.published_at,
              retail: _prod?.productSku?.length > 0 ? _prod?.productSku?.[0]?.retail : 0,
              sale_price:  _prod?.productSku?.length > 0 ? _prod?.productSku?.[0]?.sale_price : 0,
              sizes: _prod?.productSku,
              size_and_fit: _prod?.size_and_fit,
              tags: _prod?.tags,
              vendor: _prod?.vendor_name,
              vendor_handle: _prod?.vendor_handle,
              status: _prod?.status,
              status_for_sale: _prod?.status_for_sale,
              status_for_sale_nonsubscriber: _prod?.status_for_sale_nonsubscriber,
              productExternalColorAttribute: _prod?.external_color?.attribute
          }

            if (_prod.tags.indexOf('garment-bag') > -1) {
              extra = 'jacket'
            }

            let sizeObj = getSizeInfo((sizeData[0].inventory_quantity-sizeData[0].locks), sizeData[0].new_inventory_quantity, customer.has_subscription)
            let newItem: any = false;
            if (!sizeObj.has_usedItem) {
              newItem = true
            }

            let cartObj = {
              customer_id: customer_id,
              product_id: _prod.product_id,
              size: size == 'NONE' ? '' : size,
              cartItemType: _cartItemType,
              newItem: newItem,
              extraParameters: extra,
              sku_id: sizeData[0].skuid,
              isShoes: isShoes,
              customer_email: customer.email,
              sku_code: sizeData[0].sku1,
              IsLogin: true,
              product_details: productObjForMultipleItems
            }
            cartArr.push(cartObj)
          })

          dispatch(addMultipleProductToCart(() => dispatch(triggerCartPopper(true)), cartArr, setMsg, true, from))

        } catch (error) {
          console.log('add cart stylish error', error)
        }
      }
      else {
        let size = product.selectedSize;

        if (size == "" || size == undefined || size == true || size == false) {
          setMsg({ type: from, msg: "Please select a size" })
          return
        }

        let customer_id = customer.customer_id;
        let product_id = product.product_id;

        try {
          let _cartItemType = 1;
          let extra = "";
          let isShoes = false
          let sizeData = product.productSku.filter((obj: any) => { return (size == obj.option1) })

          if (product.tags.indexOf('garment-bag') > -1) {
            extra = 'jacket'
          }

          let sizeObj = getSizeInfo((sizeData[0].inventory_quantity-sizeData[0].locks), sizeData[0].new_inventory_quantity, customer.has_subscription)
          let newItem: any = false;
          if (!sizeObj.has_usedItem) {
            newItem = true
          }

          let cartObj = {
            customer_id: customer_id,
            product_id: product_id,
            size: size == 'NONE' ? '' : size,
            cartItemType: _cartItemType,
            newItem: newItem,
            extraParameters: extra,
            sku_id: sizeData[0].skuid,
            isShoes: isShoes,
            customer_email: customer.email,
            sku_code: sizeData[0].sku1,
            IsLogin: true,
            product_details: productObj
          }
          dispatch(addProductToCartFromFunctionComp(() => dispatch(triggerCartPopper(true)), cartObj, setMsg, true, from))

        } catch (error) {
          console.log('add cart stylish error')
        }
      }
    })
  }

  const addSaveForLaterBtnClick = async (product: any, from: any, pickedItemplace: any) => {
    let obj: any = { ...selectedStylishPickObj }

    setMsg({ type: "", msg: "" })

    let saveLaterTotalCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
    if (saveLaterTotalCount >= 50) {
      setMsg({ type: from, msg: "max number of save for later reached" })
      return
    }

    setShowSizeLaterPopupIndex(-1)
    let selectedSkuID = ""
    if (product.selectedSize == undefined || product.selectedSize == "") {
      setShowSizeLaterPopupIndex(from)
      return
    }
    else {
      let skuArr = product.productSku
      if (skuArr && skuArr.length > 0) {
        skuArr.map((sku: any, index: any) => {
          if (sku.option1 == product.selectedSize) {
            selectedSkuID = sku.skuid
          }
        })
      }
    }

    if (selectedSkuID == "") return

    let data = {
      "product_id": product.product_id,
      "status": 1,
      "skuid": selectedSkuID
    }


    let responce = await addSaveLaterProduct(data)
    if (responce.success) {
      let prevCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
      let savelatercountobj = { totalCount: (parseInt(prevCount) + 1) }
      dispatch(fetchCartSaveLaterSuccess(savelatercountobj))

      obj[pickedItemplace].is_save_for_later_skuid = selectedSkuID
      obj[pickedItemplace].is_save_for_later = true
      obj[pickedItemplace].is_save_later_now = true

      if (document.querySelector('.header_save_later_btn') != null) {
        document.querySelector('.header_save_later_btn').style.display = "none"
        document.querySelector('.header_save_later_btn_filled').style.display = "block"
        if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "none"

        setTimeout(() => {
          document.querySelector('.header_save_later_btn').style.display = "block"
          document.querySelector('.header_save_later_btn_filled').style.display = "none"
          if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "flex"
        }, 2000)
      }

      setSelectedStylishPickObj(obj)
    }
  }

  const addSaveForLaterFromPopup = async (product: any, pickedItemplace: any, skuId: any) => {
    let obj: any = { ...selectedStylishPickObj }

    setMsg({ type: "", msg: "" })


    let data = {
      "product_id": product.product_id,
      "status": 1,
      "skuid": skuId
    }

    let responce = await addSaveLaterProduct(data)
    if (responce.success) {
      let prevCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
      let savelatercountobj = { totalCount: (parseInt(prevCount) + 1) }
      dispatch(fetchCartSaveLaterSuccess(savelatercountobj))

      obj[pickedItemplace].is_save_for_later_skuid = skuId
      obj[pickedItemplace].is_save_for_later = true
      obj[pickedItemplace].is_save_later_now = true

      setShowSizeLaterPopupIndex(-1)

      if (document.querySelector('.header_save_later_btn') != null) {
        document.querySelector('.header_save_later_btn').style.display = "none"
        document.querySelector('.header_save_later_btn_filled').style.display = "block"
        if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "none"

        setTimeout(() => {
          document.querySelector('.header_save_later_btn').style.display = "block"
          document.querySelector('.header_save_later_btn_filled').style.display = "none"
          if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "flex"
        }, 2000)
      }
      setSelectedStylishPickObj(obj)
    }
  }

  const removeFromSaveLater = async (prooduct: any, pickedItemplace: any) => {
    let obj: any = { ...selectedStylishPickObj }
    let data = {
      "product_id": prooduct.product_id,
      "status": 0
    }
    let dataArr = [data]
    let responce = await updateSaveLaterProduct(dataArr)
    if (responce.success) {
      let prevCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
      let savelatercountobj = { totalCount: (parseInt(prevCount) - 1) }
      dispatch(fetchCartSaveLaterSuccess(savelatercountobj))
      obj[pickedItemplace].is_save_for_later_skuid = ""
      obj[pickedItemplace].is_save_for_later = false
      delete obj[pickedItemplace].is_save_later_now
      setSelectedStylishPickObj(obj)
    }
  }
  const toggleFavourite = (e: any, id: any, isLogin: any, boardClass: any) => {
    if (isLogin != "not_login") {
      let selector = e.target.classList
      if (selector.toString().indexOf('far') > -1) {
        if (e.target.parentElement)
          // e.target.parentElement.classList.add('filled')
          selector.remove('far')
        selector.add('fas')
        // props._addRemoveFavoriteItem(parseInt(id), 1, true)
        dispatch(addRemoveFavoriteItem(parseInt(id), 1, true))
      } else if (screen.width > 767) {
        selector.remove('fas')
        selector.add('far')
        if (e.target.parentElement)
          e.target.parentElement.classList.remove('filled')
        // props._addRemoveFavoriteItem(parseInt(id), 0, true)
        dispatch(addRemoveFavoriteItem(parseInt(id), 0, true))
        const selectedBoards = [].slice.call(e.target.parentElement.children[1].children[0].children)
        selectedBoards.filter(boards => {
          if (boards.classList) {
            boards.classList.remove('selected')
          }
        })
      }
      if (screen.width <= 767) {
        let boardsList = document.querySelectorAll('.newBoard-tooltip')
        boardsList.forEach((val, ind) => {
          if (boardsList[ind]) {
            boardsList[ind].style.display = "none"
          }
        })
        let boardId = 'board_' + id
        if (document.getElementById(boardId)) {
          document.getElementById(boardId).style.display = 'block'
          if (document.querySelector('.backdrop-boardTooltip'))
            document.querySelector('.backdrop-boardTooltip').style.display = 'block'
        }
      }
    } else {
      setOpenSignupModal(!openSignupModal)
    }
  }

  const stylishBoxproductsHtml = (product: any, slideIndex:any, from:any) => {
    
    if((from == 'mobile' && window.innerWidth > 767) || (from == 'desktop' && window.innerWidth < 767)) return
    if(customer != "not_login") {
      if(currentSwiperSlide == slideIndex && alreadyViewProductArr.indexOf(product.product_id) == -1) {
        alreadyViewProductArr.push(product.product_id)
        let obj = {
          "product_id": product.product_id,
          "customer_id": customer.customer_id,
          "season": "",
          "source": "stylish-pick",
          "activity": 1                   
        }
        if(currentSwiperSlide > 1) {
          saveCustomerViewProductActivityLocal([obj])
        }
        else if (currentSwiperSlide <= 1) {
          if(currentSwiperSlide == 0) {
            tempViewProdArrObj.push(obj)
          }
          else if(currentSwiperSlide == 1) {
            if(tempViewProdArrObj.length > 0) {
              saveCustomerViewProductActivityLocal(tempViewProdArrObj)
              tempViewProdArrObj = []
            }
            saveCustomerViewProductActivityLocal([obj])
          }
        }
      }
    }
    
    let isImageOld = true
    let vlink = (product.vendor_handle != null && product.vendor_handle != "" && product.vendor_handle != undefined) ? product.vendor_handle : 'vendor';
    vlink = "/product/" + vlink + "/" + product.product_handle;
    if (product?.tags?.filter((item: any) => { if (item == 'updated-gray') return true; else return false }).length > 0) {
      isImageOld = false;
    }

    let favBoard: any = []
    if (product.favoriteBoards_string && product.favoriteBoards_string != "" && product.favoriteBoards_string.length > 0) {
      let boardIDArr = product.favoriteBoards_string.split(',')
      boardIDArr.length > 0 && boardIDArr.map((id) => {
        favBoard.push({ board_id: id })
      })
    }


    return (
      <>
        <Link legacyBehavior href="/product/[...handle]" as={vlink}>
          <a target='_blank'>
            {product?.images ? <img className={`cursor_pointer ${styles.prod_img} `} src={Gconfig.productImageUrl + product?.images[0]?.product_image_url + "?profile=b2x3"} alt={`${capitializeString(product?.attributes?.external_color?.[0]?.attribute)} ${capitializeString(product?.product_title)} by ${product?.vendor_name} Front View`} /> : ""}
          </a>
        </Link>
        <div className='favBox stylishLook'>
          <i className={product.is_favorite ? ` fas fa-heart ${isImageOld ? ' pink-heart' : ' pink-heart-new'}` : ` far fa-heart ${isImageOld ? ' pink-heart' : ' pink-heart-new'}`} onClick={(e) => toggleFavourite(e, product.product_id, customer, 'stylishpick')} onTransitionEnd={(e) => toggleFavourite(e, product.product_id, customer, 'completeLook')} id={'product_' + product.product_id} aria-hidden="true"></i>
          {customer != 'not_login' && <BoardsListTooltip boardUniqueId={'stylishlook'} boards={customerBoards.customerBoards} item={product} favoriteBoards={favBoard} comp={'products'} />}
        </div>
      </>
    )
  }

  const stylishBoxHtml = (value: any, key:any, slideIndex:any, from:any) => {
    return (
      <div key={'stylish'+key} className={styles.product_container}>
        <div className={styles.product_container_inner}>
          {
            value.bottomRightItem?.product_id == undefined ?
              <>
                {value.leftItem?.product_id != undefined ?
                  <div className={styles.product_box_one}>
                    {stylishBoxproductsHtml(value.leftItem, slideIndex, from)}
                  </div> : ""}
                {value.topRightItem?.product_id != undefined ?
                  <div className={styles.product_box_one}>
                    {stylishBoxproductsHtml(value.topRightItem, slideIndex, from)}
                  </div> : ""}
              </> :
              <>
                {value.leftItem?.product_id != undefined ? <div className={styles.product_box_one}>
                  {stylishBoxproductsHtml(value.leftItem, slideIndex, from)}
                </div> : ""}
                <div>
                  {value.topRightItem?.product_id != undefined ? <div className={`  ${styles.product_box_two}`}>
                    {stylishBoxproductsHtml(value.topRightItem, slideIndex, from)}
                  </div> : ""}
                  {value.bottomRightItem?.product_id != undefined ? <div className={` ${styles.product_box_three}`}>
                    {stylishBoxproductsHtml(value.bottomRightItem, slideIndex, from)}
                  </div> : ""}
                </div>
              </>
          }
        </div>
        {
          customer != 'not_login' && customer.has_subscription &&
          <div className={styles.circle_cart_container}
            onClick={() => {
              setSelectedStylishPickObj(value);
              setMsg({ type: "", msg: "" })
              setstylistSuggestionsPopup(true);
            }}>
            <svg className={styles.add_image} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path d="M7.5 0.00012207C3.35685 0.00012207 0 3.35698 0 7.50012C0 11.6433 3.35685 15.0001 7.5 15.0001C11.6431 15.0001 15 11.6433 15 7.50012C15 3.35698 11.6431 0.00012207 7.5 0.00012207ZM11.8548 8.3469C11.8548 8.54649 11.6915 8.7098 11.4919 8.7098H8.70968V11.4921C8.70968 11.6917 8.54637 11.855 8.34677 11.855H6.65323C6.45363 11.855 6.29032 11.6917 6.29032 11.4921V8.7098H3.50806C3.30847 8.7098 3.14516 8.54649 3.14516 8.3469V6.65335C3.14516 6.45375 3.30847 6.29044 3.50806 6.29044H6.29032V3.50819C6.29032 3.30859 6.45363 3.14528 6.65323 3.14528H8.34677C8.54637 3.14528 8.70968 3.30859 8.70968 3.50819V6.29044H11.4919C11.6915 6.29044 11.8548 6.45375 11.8548 6.65335V8.3469Z" fill="#E89CAE" />
            </svg>
            <img className={styles.shopping_image} src={Gconfig.fashionpassImageStorage + "assets/ShoppingBag.svg"} alt="Clothing rental stylist pick add to cart icon"/>
          </div>
        }
      </div>
    )
  }

  const updatingPreference = ({ success, response, profileValue }: any) => {
    if (success == true) {
      if (document.querySelector('#stylishpick_swiper')) {
        const swiper = document.querySelector('#stylishpick_swiper').swiper;
        swiper.slideTo(0);
      }
      cache.del('stylistpick')
      getStylishPickBySize(0, true)
      setCurrentPage(0)
      setprefrenceSuggestionsPopup(false)
    }
    else {
      setprefrenceSuggestionsPopup(false)
    }

  }

  const handleSize = (selectedSize: string, pickedItemplace: any, skuObj: any) => {
    let obj: any = { ...selectedStylishPickObj }
    obj[pickedItemplace].selectedSize = selectedSize
    setSelectedStylishPickObj(obj)

    if (Gconfig.environment == 'production' && location.host == 'www.fashionpass.com') {
      if (gtag) {
        gtag({
          event: "select_item",
          ecommerce: {
            items: [
              {
                item_id: skuObj.skuid,
                item_name: obj[pickedItemplace].product_title,
                currency: "USD",
                discount: skuObj.discount,
                index: 0,
                item_brand: obj[pickedItemplace].vendor_name,
                item_list_id: obj[pickedItemplace].product_id,
                item_list_name: obj[pickedItemplace].product_title,
                item_variant: obj[pickedItemplace].externalColor ? obj[pickedItemplace].externalColor.attribute : "",
                price: skuObj.price,
                quantity: 1
              }
            ]
          }
        });
      }
    }
  }

  const stylishBoxProductSizeBtnHtml = (product_dataInfo: any, type: any, pickedItemplace: string) => {
    let has_subscription = false;
    if (customer != "not_login" && customer.has_subscription) {
      has_subscription = true
    }

    return (
      <div className={"swatch"} >
        <div className={styles.swatch_style_fix}>
          {product_dataInfo.productSku.map((value: any, index: any) => {
            // let hide_sku_arr=['MU437-6', 'MU437-7', 'MU437-8', 'MU437-9', 'MU437-10']
            let sizeObj = getSizeInfo((value.inventory_quantity-value?.locks), value.new_inventory_quantity, has_subscription)
            let outOfStock = false
            if (value.option1 == 'NONE') return "";
            outOfStock = ((value.inventory_quantity-value?.locks) < 1) || (!has_subscription && (sizeObj.has_usedItem && !sizeObj.has_usedItem) || (!sizeObj.has_usedItem && !sizeObj.has_newItem))
            if ((value.inventory_quantity-value?.locks) < 1 || outOfStock) return
            return (
              <span key={'swatch'+index}>
                {/* <input  type="radio" name="sizeCheck" value={value.option1} onChange={(e)=> handleSize(e, pickedItemplace) }  disabled={value.inventory_quantity < 1 || outOfStock} /> */}
                <label className={`${selectedStylishPickObj[pickedItemplace]?.selectedSize == value.option1 ? styles.bg_selected_size : ""}`} onClick={() => handleSize(value.option1, pickedItemplace, value)} disabled={(value.inventory_quantity-value.locks) < 1 || outOfStock} >{value.option1}</label>
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  function rentalProductDetailHtml(value: any, pickedItemplace: string, from: any) {

    let vlink = (value.vendor_handle != null && value.vendor_handle != "" && value.vendor_handle != undefined) ? value.vendor_handle : 'vendor';
    vlink = "/product/" + vlink + "/" + value.product_handle;
    
    return (
      <div className={"product_box"}>

        <div className={"product_popup_image"}>
          <Link legacyBehavior href="/product/[...handle]" as={vlink}>
            <a target='_blank'>
              {value?.images ? <img src={Gconfig.productImageUrl + value?.images[0]?.product_image_url + "?profile=b2x3"} alt={value.product_titile} /> : ""}
            </a>
          </Link>
          {/* <img  src={Gconfig.productImageUrl + value?.images[0]?.product_image_url+ "?profile=b2x3"} alt={value.product_titile} /> */}
        </div>
        <div className={"product_info"}>
          <p className={"product_title"} >{value?.product_title?.toLowerCase()}</p>
          <p className={"vendor"} >{value.vendor_name ? value.vendor_name?.toLowerCase() : ""}</p>
          <p className={"color"} > color: {value?.externalColor?.attribute}</p>
          {value?.attributes?.size_and_fit ? <p className={"size_fit"}> Size: <span>{value?.attributes?.size_and_fit[0]?.attribute}</span></p> : <p className={"size_fit"}></p>}
          <div className={"product_size_button"}>
            {stylishBoxProductSizeBtnHtml(value, 'rent', pickedItemplace)}
          </div>
          {
            (showSaveForLaterBtn == true || isCartLimitReached(cart.cart, customer, selectedStylishPickObj[pickedItemplace].product_catagory).isCartLimitReached == true) ?
              <>
                {value.is_save_for_later == false ?
                  <div className={"product_rent_button sfl_button save_later_size_btn"} onClick={() => addSaveForLaterBtnClick(value, from, pickedItemplace)} >
                    <span id="saveForLaterTooltip"> SAVE FOR LATER </span>
                    {showSizeLaterPopupIndex == from ?
                      <span className='saveLaterAvailableSizeContainer stylistSuggestionSFLSizeContainer save_later_size_btn' style={{ left: 'auto', marginTop: "30px" }}>
                        <span className='pickSizeText save_later_size_btn'> Pick a Size </span>
                        {saveLaterProductSizesButtons(value, pickedItemplace)}
                      </span> : ""}
                  </div> :
                  <div className={"product_rent_button alreadySave_btn"} onClick={() => removeFromSaveLater(value, pickedItemplace)} >
                    <span id="saveForLaterTooltip"> {value.is_save_later_now ? "SUCCESSFULLY SAVED" : "ALREADY SAVED"} </span>
                  </div>
                }
              </> :
              <div className={"product_rent_button"} onClick={() => _addProductToCart(selectedStylishPickObj[pickedItemplace], 'single', from)}> <span> RENT THIS ITEM </span> </div>
          }
          {from == msg.type ? <p className={"meesage_SFL"} > {(selectedStylishPickObj[pickedItemplace].selectedSize == "NONE" && msg.msg.indexOf('size has already been') > -1) ? 'This item has already been added to your shopping bag.' : msg.msg} </p> : <p className={"meesage_SFL"} > </p>}
        </div>
      </div>
    )
  }

  function onFilterChange(val: any) {
    if (val != "" && document.querySelector('#stylishpick_swiper')) {
      const swiper = document.querySelector('#stylishpick_swiper').swiper;
      swiper.slideTo(0);
    }
    setSelectedStylistFilter(val);
    setAlreadyFetchingNewData(true)
    getStylishPickBySize(0, true, val)
    setFilterDropdownOpen(false)
    toggleFilterDropdown()
  }


  function onSwiperSlideChange(e: any) {

    setCurrentSwiperSlide(e.activeIndex)
    let totalSlides = e.slides ? e.slides.length : 1;
    if (totalSlides > e.activeIndex && (totalSlides - e.activeIndex) <= 3 && alreadyFetchingNewData == false && alreadyFetchedAllPair == false) {
      setAlreadyFetchingNewData(true)
      setCurrentPage(currentPage + 1)
      if (customer != "not_login") {
        let dressSize: any = '', top_size = '', jeans_size = '', ds = ['', ''];
        customer.tag_list.map((tag: any) => {
          if (tag.indexOf('Dress Size:') > -1) {
            dressSize = tag.split(':')
            if (dressSize && dressSize.length > 0) {
              ds[1] = dressSize[1] ? dressSize[1] : ''
              ds[0] = dressSize[2] ? dressSize[2] : ''
            }
          }
          if (tag.indexOf('Jeans Size:') > -1) {
            jeans_size = tag.split(':')[1]
          }
          if (tag.indexOf('Shirt & Top:') > -1) {
            top_size = tag.split(':')[1]
          }

        })

        if (top_size != '' && ds[0] != '' && ds[1] != '' && jeans_size != '') {
          let key = 'stylistpick_' + top_size + '' + ds[0] + '' + ds[1] + '' + jeans_size
          cache.del(key)
        }
      } else {
        cache.del('stylistpick')
      }
      getStylishPickBySize(currentPage + 1)
    }
  }

  function toggleLoginModal() {
    setOpenLoginModal(true);
    setOpenSignupModal(false);
    setTimeout(() => {
      setOpenLoginModal(false);
    }, 500);
  }

  function saveLaterProductSizesButtons(product_dataInfo: any, pickedItemplace: any) {
    let has_subscription = false;
    if (customer != 'not_login' && customer?.has_subscription) {
      has_subscription = true
    }
    return (
      <span className='size_inner_container save_later_size_btn'  >
        {product_dataInfo.productSku.map((value: any, index: any) => {
          let hide_sku_arr = ['MU437-6', 'MU437-7', 'MU437-8', 'MU437-9', 'MU437-10']
          if (value.sku && hide_sku_arr.indexOf(value.sku) > -1) {
            return;
          }
          return <span className="saveLaterSize  save_later_size_btn" key={'si'+index} style={{ color: '#000' }} onClick={() => addSaveForLaterFromPopup(product_dataInfo, pickedItemplace, value.skuid)}> {value.option1} </span>
        })}
      </span>
    )
  }

  const toggleFilterDropdown = () => setFilterDropdownOpen((prevState) => !prevState);

  return (
    <>
      {
        allStylistPickData.length == 0 && isStylistPickShow == true ?
          <div className={styles.stylist_container}>
            <h1 className={styles.stylist_heading}>Stylist Picks</h1>
            {!alreadyFetchingNewData ?
              <div className={styles.dummyLoading}>
                <Loader small={true} />
              </div>
              : ""}
          </div> : ''
      }
      {allStylistPickData.length > 0 ?
        <div className={styles.stylist_container}>
          {customer == 'not_login' || !customer.has_subscription ? <h1 className={styles.stylist_heading_nonsub}>Stylist Picks</h1> : <h1 className={styles.stylist_heading}>Stylist Picks</h1>}
          {fromNew == false ?
            <>
              <div className={styles.stylist_filter_div}>
                {fromNew == false ? <p className={styles.stylist_heading_text_two}>Your order, curated by our stylist</p> : ''}
                <Dropdown className={`${styles.dropdown_menu}`} isOpen={filterDropdownOpen} toggle={toggleFilterDropdown} direction={'down'}>
                  <DropdownToggle className={`${styles.stlistFilterDropdown} ${customer != 'not_login' && !isTablet ? 'mb-0' : ''} ${selectedStylistFilter != "" ? styles.stlistFilterDropdown_capitalize : ''}`} caret>  {selectedStylistFilter == "" ? 'What are you shopping for?' : 'Shopping for ' + selectedStylistFilter.replaceAll('-', " ").toLowerCase()} </DropdownToggle>
                  {filterDropdownOpen && <DropdownMenu className={styles.stlistFilterDropdownMenu}>
                    {/* <DropdownItem hidden> What are you shopping for? </DropdownItem> */}
                    <DropdownItem onClick={() => onFilterChange('')} > --- </DropdownItem>
                    {stylistFilterArr && stylistFilterArr.length > 0 && stylistFilterArr.map((val, ind) => {
                      return (
                        <DropdownItem key={'drop'+ind} onClick={() => onFilterChange(val)} className={val == selectedStylistFilter ? styles.selected_text : ''}> {val ? '' + val.replaceAll('-', " ").toLowerCase() : ""} </DropdownItem>
                      )
                    })}
                  </DropdownMenu>}
                </Dropdown>

                {/* <select className={styles.stlistFilterDropdown}  style={{ textTransform:"capitalize", marginBottom:"15px" }} onChange={(e)=>{ onFilterChange(e) }}  value={selectedStylistFilter} >   
                <option value="" disabled hidden > What are you shopping for?  </option>
                <option value="" > None </option>
                {stylistFilterArr && stylistFilterArr.length > 0 && stylistFilterArr.map((val, ind) => {
                  return(
                    <option value={val}> {val ? '' + val.replaceAll('-', " ").toLowerCase() : ""} </option>
                  )
                })}
              </select> */}

              </div> </> : ""}
          {fromNew == true && customer !== 'not_login' && !customer.has_subscription ? <p className={styles.stylist_heading_text}>Your order, curated by our stylist</p> : ''}
          {inCompleteProfileOverlay != true && customer != 'not_login' && completeProfileNotFill == false ? <p className={`${styles.product_filters_applied} container d-none d-md-block`}> <span className='cursor-pointer' onClick={() => { setprefrenceSuggestionsPopup(true) }} >  See what filters we applied </span> </p> : ""}
          {inCompleteProfileOverlay ?
            <div className={styles.incomp_profile_overlay_container} >
              {isPairNotFound ?
                <>
                  <p className={styles.overlay_text} > ALL OUTFITS ARE RENTED OUT IN YOUR SIZE! <br /> CHANGE YOUR SIZES TO SEE OTHER OPTIONS. </p>
                  <div className={styles.overlay_btn_box} >
                    <button className={styles.overlay_btn} onClick={() => { setprefrenceSuggestionsPopup(true) }}> UPDATE SIZE PREFERENCES </button>
                  </div>
                </>
                :
                <>
                  <p className={styles.overlay_text} > ADD YOUR SIZES SO WE CAN RECOMMEND <br /> OUTFITS AVAILABLE IN YOUR SIZE! </p>
                  <div className={styles.overlay_btn_box} >
                    <Link className={styles.overlay_btn} href="/my-account?tab=1">COMPLETE YOUR PROFILE </Link>
                  </div>
                </>
              }
            </div> : ""}

          {alreadyFetchingNewData ?
            <div className={styles.loader}>
              <Loader small={true} />
            </div>
            : ""}

          <div className={`${styles.stylist_sub_container_main} ${(inCompleteProfileOverlay || alreadyFetchingNewData) ? styles.stylist_sub_container_main_disabled : ""} `} >
            <MediaQuery minWidth={768}>
              <Swiper
                modules={[Pagination, Navigation]}
                slidesPerView={1}
                centeredSlides={true}
                initialSlide={currentSwiperSlide}
                navigation={true}
                spaceBetween={15}
                className={`plan-carosel-mobile plan-carosel-mobile-stylish-pick plan-carosel-desktop-stylish-pick`}
                onSlideChange={(e) => { onSwiperSlideChange(e) }}
                id="stylishpick_swiper"
                simulateTouch={false}
              >
                {getRangeBetweenTwoNumbers({ start: 1, end: Math.ceil(allStylistPickData.length / noOfIteminPerSlide) }).map((val, ind) => {
                  return (
                    <SwiperSlide key={'mainbox'+ind}>
                      <div key={'range_key' + val} className={!isSmallScreen ? 'd-flex' : 'd-flex justify-content-center'} style={{ flexWrap: 'wrap' }}>
                        {allStylistPickData.map((value: any, d_ind: any) => {
                          d_ind = d_ind + 1
                          let startingPoint = ((val - 1) * noOfIteminPerSlide) + (!isSmallScreen ? 1 : 2);
                          let endingPoint = val * noOfIteminPerSlide
                          if (d_ind >= startingPoint && d_ind <= endingPoint) {
                            return (
                              stylishBoxHtml(value, d_ind, ind, 'desktop')
                            )
                          }
                          else return
                        })
                        }
                      </div>
                    </SwiperSlide>
                  )
                })
                }

              </Swiper>

            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <Swiper
                modules={[Pagination, Navigation, FreeMode]}
                slidesPerView={1.15}
                centeredSlides={true}
                initialSlide={currentSwiperSlide}
                navigation={true}
                spaceBetween={15}
                onSlideChange={(e) => { onSwiperSlideChange(e) }}
                // freeMode={true}
                id="stylishpick_swiper"

                className={`plan-carosel-mobile plan-carosel-mobile-stylish-pick`}
                simulateTouch={true}
              >
                {allStylistPickData.map((value: any, ind: any) => {
                  return (
                    <SwiperSlide key={'slide'+ind}>
                      {stylishBoxHtml(value, ind, ind, 'mobile')}
                    </SwiperSlide>
                  )
                })
                }
              </Swiper>
            </MediaQuery>
            {inCompleteProfileOverlay != true && customer != 'not_login' && completeProfileNotFill == false ? <p className={`${styles.product_filters_applied} container d-block d-md-none`}> <span className='cursor-pointer' onClick={() => { setprefrenceSuggestionsPopup(true) }} >  See what filters we applied </span> </p> : ""}
          </div>
        </div>
        : <></>}


      <Modal className="product_popup_modal_SFL" size="lg" centered isOpen={stylistSuggestionsPopup} toggle={() => { setstylistSuggestionsPopup(!stylistSuggestionsPopup) }}>
        <ModalHeader className={"product_modal_head"} toggle={() => { setstylistSuggestionsPopup(!stylistSuggestionsPopup) }}>
        </ModalHeader>
        <ModalBody className={"product_modal_body"}>
          {selectedStylishPickObj?.leftItem?.product_id != undefined ?
            rentalProductDetailHtml(selectedStylishPickObj?.leftItem, 'leftItem', 1)
            : ""}
          {selectedStylishPickObj?.topRightItem?.product_id != undefined ?
            rentalProductDetailHtml(selectedStylishPickObj?.topRightItem, 'topRightItem', 2)
            : ""}
          {selectedStylishPickObj?.bottomRightItem?.product_id != undefined ?
            rentalProductDetailHtml(selectedStylishPickObj?.bottomRightItem, 'bottomRightItem', 3)
            : ""}
          {showSaveForLaterBtn || (isCartLimitReached(cart.cart, customer).success && isCartLimitReached(cart.cart, customer).remainingRentalCount < selectedStylishPickObj.toatalAvailableItemForCount) || isCartLimitReached(cart.cart, customer).isCartLimitReached == true ? '' : <div className={"product_all_item"} onClick={() => _addProductToCart(selectedStylishPickObj, 'multiple', 4)}  ><span> RENT ALL ITEMS </span></div>}
          {msg.type == "4" ? <p className={"meesage_SFL"} > {msg.msg} </p> : <p className={"meesage_SFL"} > </p>}
        </ModalBody>
      </Modal>

      {prefrenceSuggestionsPopup ? <UpdatePrefrencePopup isOpen={prefrenceSuggestionsPopup} toggle={() => { setprefrenceSuggestionsPopup(!prefrenceSuggestionsPopup) }} setInCompleteProfile={setCompSetProfile} callBackFunc={updatingPreference} btn_name='See Stylist picks' /> : ''}
      <BodyTypePopup modal={bodyTypePopup} toggle={() => setBodyTypePopup(!bodyTypePopup)} />
      <SignupPopup isOpen={openSignupModal} toggle={() => toggleFavourite(false, 0, customer, '')} openLogin={toggleLoginModal} />
      <Login isSignup={true} isOpen={openLoginModal} />
    </>

  );
};

export default StylistSuggestions;