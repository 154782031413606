// import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '@/libraries/responsive';
import { useEffect, useState } from 'react';
export const useResponsive = () => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const isTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);
  const isLaptop = useMediaQuery(`(max-width: ${Breakpoints.lg}px)`);
  const isDesktop = useMediaQuery(`(max-width: ${Breakpoints.xl}px)`);
  return { isMobile, isTablet, isLaptop, isDesktop };
};

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = () => setMatches(!!mediaQueryList.matches);
    listener();
    mediaQueryList.addListener(listener);
    return () => mediaQueryList.removeListener(listener);
  }, [query]);

  return matches;
};
