import React from 'react';
import { Responsive, ResponsiveType } from '@/libraries/responsive';

interface IProps {
  children: React.ReactNode;
  /**
   *
   * min = Size Greater Than Desktop Size
   *
   * max = Size Less than or Equals to Desktop Size
   */
  widthType?: ResponsiveType;
}

const DesktopView: React.FC<IProps> = ({ widthType = 'max', children }) => (
  <Responsive variant="xl" widthType={widthType}>
    {children}
  </Responsive>
);

export default DesktopView;
