import React from 'react';
import { Responsive, ResponsiveType } from '@/libraries/responsive';

interface IProps {
  children: React.ReactNode;
  /**
   *
   * min = Size Greater Than Tablet Size
   *
   * max = Size Less than or Equals to Tablet Size
   */
  widthType?: ResponsiveType;
}

const TabletView: React.FC<IProps> = ({ widthType = 'max', children }) => (
  <Responsive variant="md" widthType={widthType}>
    {children}
  </Responsive>
);

export default TabletView;
