import React from 'react';
import { Breakpoints, BreakpointsType, ResponsiveType } from '@/libraries/responsive';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false });

interface IProps {
  variant: BreakpointsType;
  widthType: ResponsiveType;
  children: React.ReactNode;
}

const Responsive: React.FC<IProps> = ({ variant, widthType, children }) => {
  if (widthType === 'min') return <MediaQuery minWidth={Breakpoints[variant] + 1}> {children} </MediaQuery>;
  else return <MediaQuery maxWidth={Breakpoints[variant]}> {children} </MediaQuery>;
};

export default Responsive;
